import { PureComponent } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import Bungee from '../components/Bungee';

class Footer extends PureComponent {
  render() {
    return (
      <footer
        css={css`
          background: #0f2b33;
          color: #fff;
        `}
      >
        <div
          css={css`
            background: white;
            border-bottom-left-radius: 1em;
            border-bottom-right-radius: 1em;
            height: 2em;
          `}
        />
        <div css={(theme) => [theme.mixin.section]}>
          <a
            href='mailto:blaess@indiscernible.org'
            css={css`
              position: relative;
              z-index: 1;
              display: block;
              margin: -3em 0 2em;
              text-decoration: none;
              text-align: center;
            `}
          >
            <img src='./hund.png' alt='Bläss' />
            <Bungee
              content='Schreib uns:'
              regular='#0F2B33'
              outline='#fff'
              shade='#fff'
              css={css`
                display: block;
                font-size: 60%;
              `}
            />
            <Bungee
              content='blaess@indiscernible.org'
              regular='#0F2B33'
              outline='#fff'
              shade='#fff'
              css={css`
                display: block;
                line-height: 0.5;
              `}
            />
          </a>
          <div
            css={(theme) => [
              css`
                display: flex;
                justify-content: space-between;
                font-size: 20%;
              `,
            ]}
          >
            <div></div>
            <div>◆</div>
            <div></div>
            <div>◆</div>
            <div></div>
            <div>◆</div>
            <div></div>
            <div>■</div>
            <div></div>
            <div>◆</div>
            <div></div>
            <div>◆</div>
            <div></div>
            <div>◆</div>
            <div></div>
          </div>
          <div
            css={css`
              font-size: 0.8rem;

              @media (min-width: 40em) {
                display: flex;
                justify-content: space-between;
              }
              margin: 3em 0;
            `}
          >
            <div>
              Web Design by{' '}
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://flxlabs.com'
                css={css`
                  color: inherit;
                `}
              >
                flxlabs.com
              </a>
            </div>
            <div>© 2024 indiscernible music</div>
            <div>
              <a
                href='./archive/'
                css={css`
                  color: inherit;
                `}
              >
                archive
              </a>
            </div>
            <div>
              Vector Design by{' '}
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.vecteezy.com'
                css={css`
                  color: inherit;
                `}
              >
                vecteezy.com
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
